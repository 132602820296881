<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="新订单" name="first">
        <div v-if="orderlistlength == 0" class="nodata">暂无数据</div>
        <div v-else>
          <div
            class="orderlist"
            v-for="(item, key) in orderlist"
            :key="key"
            @click="showDetail(item.works_id)"
          >
            <div class="ygdleft">
              <p class="biaoti">{{ item.title }}</p>
              <p>
                <span>{{ item.name }}</span
                ><span>{{ item.editor }}</span>
              </p>
            </div>
            <div class="ygdright">
              <div class="urgent" v-show="item.attribute == 1">加急</div>
              <div class="nourgent" v-show="item.attribute == 0">普通</div>
              <div style="height: 20px"></div>
              <span>{{ item.operation_time }}</span>
            </div>
          </div>
          <div class="ygdPage">
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange"
              :page-size="pagesize"
              :page-sizes="[10, 20, 30]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <!--被驳回订单-->
      <el-tab-pane label="订单列表" name="second">
        <div class="status_form">
          <el-form
                  size="mini"
                  :inline="true"
                  :model="formInline"
                  :label-position="labelPosition"
                  label-width="80px"
                  class="demo-form-inline"
          >
            <!--          <el-form-item label="作者">-->
            <!--            <el-input v-model="formInline.editor" placeholder="作者"></el-input>-->
            <!--          </el-form-item>-->
            <el-form-item label="文章题目">
              <el-input
                      v-model="formInline.title"
                      placeholder="文章题目"
              ></el-input>
            </el-form-item>
            <!--          <el-form-item label="处理人">-->
            <!--            <el-input-->
            <!--                    v-model="formInline.realname"-->
            <!--                    placeholder="处理人"-->
            <!--            ></el-input>-->
            <!--          </el-form-item>-->
            <el-form-item label="属性">
              <el-col :span="22">
                <el-select
                        v-model="formInline.attribute"
                        clearable
                        placeholder="属性"
                >
                  <el-option label="普通" value="0"></el-option>
                  <el-option label="加急" value="1"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="订单状态">
              <el-col :span="22">
                <el-select
                        v-model="formInline.qikan_state"
                        clearable
                        placeholder="订单状态"
                >
                  <el-option label="待处理" value="55"></el-option>
                  <el-option label="平台待审" value="0"></el-option>
                  <el-option label="平台已审" value="6"></el-option>
                  <el-option label="期刊待审" value="2"></el-option>
                  <el-option label="期刊已审" value="5"></el-option>
                  <el-option label="平台驳回" value="71"></el-option>
                  <el-option label="期刊驳回" value="72"></el-option>
                  <el-option label="作者已确认" value="47"></el-option>
                  <el-option label="出刊邮寄" value="4"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <!--          <el-form-item label="投稿时间">-->
            <!--            <el-date-picker-->
            <!--                    type="date"-->
            <!--                    value-format="yyyy-MM-dd"-->
            <!--                    placeholder="选择日期"-->
            <!--                    v-model="formInline.starttime"-->
            <!--                    style="width: 130px"-->
            <!--            ></el-date-picker>-->
            <!--            <span class="to">至</span>-->
            <!--            <el-date-picker-->
            <!--                    type="date"-->
            <!--                    value-format="yyyy-MM-dd"-->
            <!--                    placeholder="选择日期"-->
            <!--                    v-model="formInline.endtime"-->
            <!--                    style="width: 130px"-->
            <!--            ></el-date-picker>-->
            <!--          </el-form-item>-->

            <el-form-item>
              <el-button type="danger" @click="rejectOrder">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="secondorderlength == 0" class="nodata">暂无数据</div>
        <div v-else>
<!--          <div-->
<!--            class="orderlist"-->
<!--            v-for="(item, key) in orderlist2"-->
<!--            :key="key"-->
<!--            @click="showDetail2(item.works_id)"-->
<!--          >-->
<!--            <div class="ygdleft">-->
<!--              <p class="biaoti">{{ item.title }}</p>-->
<!--              <p>-->
<!--                <span>{{ item.name }}</span-->
<!--                ><span>{{ item.editor }}</span>-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="ygdright">-->
<!--              <div class="urgent" v-show="item.attribute == 1">加急</div>-->
<!--              <div style="height: 20px"></div>-->
<!--              <span>2020-12-29</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="ygdPage">-->
<!--            <el-pagination-->
<!--              class="fy"-->
<!--              small-->

<!--              @size-change="handleSizeChange2"-->
<!--              :page-size="pagesize2"-->
<!--              :page-sizes="[10, 20, 30]"-->
<!--              layout="total, sizes, prev, pager, next, jumper"-->
<!--              @current-change="current_change2"-->
<!--              :total="total2"-->
<!--              background-->
<!--            >-->
<!--            </el-pagination>-->
<!--          </div>-->

          <div class="tab1" v-show="status">

            <div class="container_table">
              <el-table
                      :header-cell-style="headeRowClass"
                      :data="tableData"
                      stripe
                      style="width: 100%"
                      :default-sort="{ prop: 'date', order: 'descending' }"
              >
                <el-table-column prop="title" label="文章标题" sortable>
                </el-table-column>
                <el-table-column prop="editor" label="作者" sortable>
                </el-table-column>
                <el-table-column label="投稿时间" prop="operation_time" sortable>
                </el-table-column>

                <!--          <el-table-column prop="realname" label="当前处理人" sortable>-->
                <!--          </el-table-column>-->
                <el-table-column prop="state" label="订单状态" sortable>
                  <template slot-scope="scope">
                    <span v-if="scope.row.state == 55">待处理 </span>
                    <span v-if="scope.row.state == 0">平台待审 </span>
                    <span v-if="scope.row.state == 6">平台已审</span>
                    <span v-if="scope.row.state == 2">期刊待审 </span>
                    <span v-if="scope.row.state == 5">期刊已审</span>
                    <span v-if="scope.row.state == 71">平台驳回 </span>
                    <span v-if="scope.row.state == 72">期刊驳回</span>
                    <span v-if="scope.row.state == 47">作者已确认 </span>
                    <span v-if="scope.row.state == 4">出刊邮寄</span>
                  </template>
                </el-table-column>
                <el-table-column prop="attribute" label="属性" sortable>
                  <!--在职是0，离职是1-->
                  <template slot-scope="scope">
                    <span v-if="scope.row.attribute == 0">普通 </span>
                    <span v-if="scope.row.attribute == 1">加急</span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button
                            @click="cc(scope.row)"
                            type="text"
                            size="small"
                    >查看</el-button
                    >
                    <!--              <el-button @click="receive(scope.row)" type="text" size="small"-->
                    <!--              >更换处理人</el-button-->
                    <!--              >-->
                  </template>
                </el-table-column>
              </el-table>
              <div class="orderPage">
                <el-pagination
                        class="fy"
                        small
                        @size-change="handleSizeChange"
                        :page-size="pagesize"
                        :page-sizes="[10, 20, 30]"
                        layout="total, sizes, prev, pager, next, jumper"
                        @current-change="current_change"
                        :total="total"
                        background
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { orderlist } from '@/API/YGD/orderlist'
import order from '../components/order'
import { searchOrder,fwsorderalls } from '@/API/orderstatus'
export default {
  components: {
    order
  },
  data () {
    return {
      labelPosition: 'right',//标签label对齐方式
      status: true,//控制显示详情还是显示列表
      loading: false,
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //新订单数据
      total2: 0, //默认数据总数
      pagesize2: 10, //每页的数据条数
      currentPage2: 1, //二次订单数据
      activeName: 'first',
      worksid: "",//订单id
      orderlist: [ //订单列表数据
      ],
      orderlistlength: 0,//判断是否有数据
      orderlist2: [ //订单列表数据
      ],
      //表单基本数据
      formInline: {
        id: "",
        page: "",
        limit: "",
        starttime: "",
        endtime: "",
        editor: "",
        title: "",
        attribute: "",
        realname: "",
        qikan_name: "",
        qikan_state: ""
      },
      secondorderlength: 0//判断是否有数据
    }
  },
  methods: {
    //选项卡切换
    handleClick (tab, event) {
      console.log(tab.index);

    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.todoOrder();
    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.todoOrder();
    }, // 分页
    current_change2: function (currentPage) {
      this.currentPage2 = currentPage;
      this.rejectOrder();
    }, handleSizeChange2 (val) {
      this.pagesize2 = val;
      this.rejectOrder();
    },

    // 更改表头样式
    headeRowClass({
                    row,
                    column,
                    rowIndex,
                    columnIndex
                  }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    //查询代办订单数据
    todoOrder () {
      orderlist(localStorage.getItem('UserId'), this.currentPage, this.pagesize, 0).then(res => {

        if (res.data.code == 0) {
          this.orderlist = res.data.data
          this.orderlistlength = this.orderlist.length;
          this.total = res.data.total
        }
      })


    },
    showDetail (wid) {
      this.$router.push({ path: '/Staff/TodoOrder/neworderDetail', query: { id: this.$parent.now, wid: wid } })
      console.log(this.$parent)
    },
    showDetail2 (wid) {
      this.$router.push({ path: '/Staff/TodoOrder/rejectedorderDetail', query: { id: this.$parent.now, wid: wid } })

    },
    //查询订单列表
    rejectOrder () {
      // orderlist(localStorage.getItem('UserId'), this.currentPage2, this.pagesize, 3).then(res => {
      //   if (res.data.code == 0) {
      //     this.orderlist2 = res.data.data
      //     this.secondorderlength = this.orderlist2.length;
      //     this.total2 = res.data.total
      //   }
      // })
      let staff_id = localStorage.getItem('UserId')
      this.formInline.id = localStorage.getItem('FuWuId');
      this.formInline.limit = this.pagesize
      this.formInline.page = this.currentPage
      console.log(this.formInline);
      fwsorderalls(this.formInline.id, this.formInline.page,
              this.formInline.limit, this.formInline.starttime, this.formInline.endtime, this.formInline.editor,
              this.formInline.title, this.formInline.attribute, this.formInline.realname, this.formInline.qikan_name, this.formInline.qikan_state,staff_id).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.data
          this.secondorderlength = this.tableData.length;
          this.total = res.data.total
        }
      })
    },
    cc (tab) {
     // alert(1);
      let wid = tab.works_id;
     // alert(wid);
      this.$router.push({ path: '/Staff/TodoOrder/rejectedorderDetail', query: { id: this.$parent.now, wid: wid } })
    //  this.$router.push({ path: "/Staff/TodoOrder/orderStatusDetail", query: { id: 2, wid: wid } })

    },
  },
  mounted () {
    this.todoOrder();
    this.rejectOrder();
  }
}

</script>

<style>
.num {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.num span {
  margin-right: 30px;
  cursor: pointer;
}
.num span:hover {
  color: #ff1918;
}
.ygdPage {
  display: flex;
  justify-content: center;
}
.stepicon {
  width: 70px;
}

.trace {
  background: #f7f7f7;
  border-radius: 4px;
}

.trace_title {
  padding-left: 20px;
  box-sizing: border-box;
  height: 30px;
  font-size: 14px;
}

.box {
  display: flex;
}

.box .trace_date {
  flex: 1;
}

.box .trace_date span {
  margin: 5px;
}

.box .trace_des {
  flex: 3;
}
.nodata {
  text-align: center;
  line-height: 80px;
  color: #e6e6e6;
}
/*文章样式*/

.articleTitle {
  height: 80px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 80px;
  text-align: center;
}

.xs {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
}

.bt {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.xs .author {
  margin-right: 10px;
}

.file {
  width: 50%;
  height: 40px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.file span {
  font-size: 14px;
}

.articletext {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin: 10px;
}
.contact {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
}
.contact span {
  margin: 0 5px;
  font-size: 14px;
}
.nourgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: lightseagreen;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}
</style>